import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, Divider, Link, CircularProgress, Box, Avatar } from "@mui/material";
import axios from "axios";
import { formatNumber, formatDateToKST } from "../utils/formatters";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLinkProps } from "../utils/linkUtils";

function RealtimeSearch() {
    const theme = useTheme();
    const [searchTerms, setSearchTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const linkProps = useLinkProps()();

    useEffect(() => {
        const fetchSearchTerms = async () => {
            try {
                const response = await axios.get("/api/v1/search/trending");
                setSearchTerms(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchSearchTerms();
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/search/realtime-search"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">실시간 검색어</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/search/realtime-search"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <List
                        sx={{
                            flexGrow: 1,
                            overflow: "auto",
                            maxHeight: "500px",
                            pt: 0,
                            pb: 0,
                            "&::-webkit-scrollbar": {
                                width: "6px",
                                backgroundColor: "#F5F5F5",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "10px",
                                backgroundClip: "padding-box",
                                border: "2px solid transparent",
                                "&:hover": {
                                    backgroundColor: "#555",
                                },
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: "#888 #F5F5F5",
                        }}
                    >
                        {searchTerms.map((term, index) => (
                            <React.Fragment key={`${term.trends_link}-${index}`}>
                                {index > 0 && <Divider />}
                                <ListItem sx={{ py: 1 }}>
                                    <Box sx={{ mr: 2, minWidth: "30px", textAlign: "center" }}>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                            {index + 1}
                                        </Typography>
                                    </Box>
                                    <Avatar src={term.trends_thumbnails_uri} alt={term.trends_title} variant="rounded" sx={{ width: 80, height: 80, mr: 2 }} />
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Link href={term.trends_news_url} {...linkProps}>
                                            <Typography variant="subtitle1">{term.trends_title}</Typography>
                                        </Link>
                                        <Typography variant="body2" color="textSecondary">
                                            {`검색량 ${formatNumber(term.trends_count)} • ${formatDateToKST(term.trends_publish_datetime)}`}
                                        </Typography>
                                        {term.trends_news_title && (
                                            <Box sx={{ mt: 1 }}>
                                                <Link href={term.trends_news_url} {...linkProps}>
                                                    <Typography variant="body2">{term.trends_news_title}</Typography>
                                                </Link>
                                                <Typography variant="caption" color="textSecondary">
                                                    {term.trends_news_source}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
}

export default RealtimeSearch;
