import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Box,
    CircularProgress,
    Chip,
    Divider,
    useTheme,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Slide,
    Link,
    Rating,
    useScrollTrigger,
} from "@mui/material";
import { Comment, TrendingUp } from "@mui/icons-material";
import StyledTableHeader from "../../components/StyledTableHeader";
import axios from "axios";
import { formatCurrency, formatKNumber } from "../../utils/formatters";
import HighlightedText from "../../components/HighlightedText";
import SearchInput from "../../components/SearchInput";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";

function RealtimeShopping() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const observer = useRef();
    const lastScrollTop = useRef(0);
    const searchBarRef = useRef(null);
    const linkProps = useLinkProps()();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const lastItemElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const fetchItems = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/v1/shopping/realtime-shopping?page=${page}&limit=100&search=${searchTerm}`);
            setItems((prevItems) => (page === 1 ? response.data.data : [...prevItems, ...response.data.data]));
            setHasMore(response.data.data.length > 0);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching realtime shopping items:", err);
            setError("데이터를 불러오는 데 실패했습니다.");
            setLoading(false);
        }
    }, [page, searchTerm]);

    useEffect(() => {
        setPage(1);
    }, [searchTerm]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems, page, searchTerm]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDelta = scrollTop - lastScrollTop.current;
            const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;

            if (scrollTop > searchBarHeight) {
                if (scrollDelta > 10) {
                    setIsSearchVisible(false);
                } else if (scrollDelta < -10) {
                    setIsSearchVisible(true);
                }
            } else {
                setIsSearchVisible(true);
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSearch = useCallback(
        (newSearchTerm) => {
            setSearchTerm(newSearchTerm);
            setPage(1);
            scrollToTop();
        },
        [scrollToTop]
    );

    const tableHeaders = [{ label: "쇼핑몰" }, { label: "순위", align: "right" }, { label: "상품명" }, { label: "가격", align: "right" }, { label: "평점(상품평)", align: "right" }];

    const renderTableView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="실시간 쇼핑 상품">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={`${item.goods_id}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} ref={index === items.length - 1 ? lastItemElementRef : null}>
                            <TableCell component="th" scope="row">
                                {item.shoppingmall_name}
                            </TableCell>
                            <TableCell align="right">
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <TrendingUp fontSize="xsmall" sx={{ mr: 0.5 }} />
                                    <Typography variant="body2">{item.ranking}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src={item.image_url} alt={item.goods_title} style={{ width: 60, height: 60, marginRight: 8, objectFit: "cover" }} />
                                    <Link href={item.goods_url} {...linkProps} sx={{ color: "inherit", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                                        <HighlightedText text={item.goods_title} highlight={searchTerm} variant="body2" />
                                    </Link>
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                {item.discount_price === item.original_price ? (
                                    <Typography variant="body2" color="primary">
                                        {formatCurrency(item.original_price)}
                                    </Typography>
                                ) : item.discount_price === 0 ? (
                                    <Typography variant="body2" color="primary">
                                        {formatCurrency(item.original_price)}
                                    </Typography>
                                ) : item.original_price === 0 ? (
                                    <Typography variant="body2" color="primary">
                                        {formatCurrency(item.discount_price)}
                                    </Typography>
                                ) : (
                                    <Box>
                                        <Typography variant="body2" color="primary">
                                            {formatCurrency(item.discount_price)}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ textDecoration: "line-through", display: "inline" }}>
                                            {formatCurrency(item.original_price)}
                                        </Typography>
                                        <Typography variant="caption" color="secondary" sx={{ ml: 0.5 }}>
                                            ({item.discount_rate}%)
                                        </Typography>
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {item.rate_point > 0 ? (
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Rating value={item.rate_point} precision={0.1} readOnly size="small" />
                                            <Typography variant="body2" sx={{ ml: 1 }}>
                                                {item.rate_point.toFixed(1)}
                                            </Typography>
                                        </Box>
                                        {item.comment_count > 0 && (
                                            <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                <Comment fontSize="xsmall" sx={{ mr: 0.5 }} />
                                                <Typography variant="body2">{formatKNumber(item.comment_count)}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ) : item.comment_count > 0 ? (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <Comment fontSize="xsmall" sx={{ mr: 0.5 }} />
                                        <Typography variant="body2">{formatKNumber(item.comment_count)}</Typography>
                                    </Box>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderCardView = () => (
        <Grid container spacing={2}>
            {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={`${item.goods_id}-${index}`} ref={index === items.length - 1 ? lastItemElementRef : null}>
                    <Card elevation={3}>
                        <CardActionArea href={item.goods_url} {...linkProps}>
                            <CardMedia component="img" height="140" image={item.image_url} alt={item.goods_title} />
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                        <img
                                            src={`/images/shopping-icons/${item.shoppingmall_id.toLowerCase()}.png`}
                                            alt={item.shoppingmall_name}
                                            style={{
                                                width: "40px",
                                                height: "20px",
                                                marginRight: "8px",
                                                objectFit: "contain",
                                            }}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.style.display = "none";
                                            }}
                                        />
                                        <Typography variant="caption">{item.shoppingmall_name}</Typography>
                                    </Box>

                                    <Chip icon={<TrendingUp />} label={`${item.ranking}위`} color="primary" size="small" />
                                </Box>
                                <HighlightedText text={item.goods_title} highlight={searchTerm} variant="subtitle2" />
                                <Box mt={1}>
                                    {item.discount_price === item.original_price || item.discount_price === 0 ? (
                                        <Typography variant="body1" color="primary">
                                            {formatCurrency(item.original_price)}
                                        </Typography>
                                    ) : item.original_price === 0 ? (
                                        <Typography variant="body1" color="primary">
                                            {formatCurrency(item.discount_price)}
                                        </Typography>
                                    ) : (
                                        <>
                                            <Typography variant="body1" color="primary">
                                                {formatCurrency(item.discount_price)}
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" color="text.secondary" sx={{ textDecoration: "line-through", mr: 1 }}>
                                                    {formatCurrency(item.original_price)}
                                                </Typography>
                                                <Typography variant="caption" color="secondary">
                                                    ({item.discount_rate}%)
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                <Box display="flex" alignItems="center" mt={1}>
                                    {item.rate_point > 0 && (
                                        <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                                            <Rating value={item.rate_point} precision={0.1} readOnly size="small" />
                                            <Typography variant="body2" sx={{ ml: 0.5, mr: 0.5 }}>
                                                {item.rate_point.toFixed(1)}
                                            </Typography>
                                        </Box>
                                    )}
                                    {item.comment_count > 0 && (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Comment fontSize="0.8rem" />
                                            <Typography variant="body2" sx={{ ml: 0.5 }}>
                                                {formatKNumber(item.comment_count)}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    실시간 쇼핑
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    지금 가장 인기 있는 상품
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />

                <Slide appear={false} direction="down" in={isSearchVisible}>
                    <Box
                        ref={searchBarRef}
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: 64,
                            zIndex: 1,
                            backgroundColor: "transparent",
                            padding: "10px 0",
                        }}
                    >
                        <SearchInput onSearch={handleSearch} theme={theme} placeholder="상품 검색..." initialValue={searchTerm} />
                    </Box>
                </Slide>

                {isMobile ? renderCardView() : renderTableView()}
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default RealtimeShopping;
