import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, Divider, Link, CircularProgress, Box, Avatar } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import axios from "axios";
import { formatNumber, formatDateToKST } from "../utils/formatters";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLinkProps } from "../utils/linkUtils";

function YoutubeVideos() {
    const theme = useTheme();
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const linkProps = useLinkProps()();

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get("/api/v1/youtube/best");
                setVideos(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/youtube/realtime-youtube"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">인기 유튜브 동영상</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/youtube/realtime-youtube"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <List
                        sx={{
                            flexGrow: 1,
                            overflow: "auto",
                            maxHeight: "500px",
                            "&::-webkit-scrollbar": {
                                width: "6px",
                                backgroundColor: "#F5F5F5",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "10px",
                                backgroundClip: "padding-box",
                                border: "2px solid transparent",
                                "&:hover": {
                                    backgroundColor: "#555",
                                },
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: "#888 #F5F5F5",
                        }}
                    >
                        {videos.map((video, index) => (
                            <React.Fragment key={video.video_id}>
                                {index > 0 && <Divider />}
                                <ListItem alignItems="flex-start" sx={{ py: 1.5 }}>
                                    {" "}
                                    {/* 상하 패딩 줄임 */}
                                    <Box sx={{ mr: 2, textAlign: "center" }}>
                                        <Avatar variant="rounded" src={video.video_thumbnails_uri} alt={video.video_title} sx={{ width: 120, height: 68, mb: 0.5 }} />
                                        <Typography variant="caption" color="textSecondary" sx={{ display: "block", fontSize: "0.7rem", lineHeight: 1.2 }}>
                                            {video.channel_title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Link href={`https://www.youtube.com/watch?v=${video.video_id}`} {...linkProps}>
                                            <Typography variant="subtitle1" gutterBottom sx={{ mb: 0.5 }}>
                                                {" "}
                                                {/* 하단 마진 줄임 */}
                                                {video.video_title}
                                            </Typography>
                                        </Link>
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                                            {" "}
                                            {/* 세로 배치로 변경, 간격 줄임 */}
                                            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 2 }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <VisibilityIcon fontSize="0.8rem" sx={{ mr: 0.5 }} />
                                                    <Typography variant="body2">{formatNumber(video.video_view_count)}</Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <ThumbUpIcon fontSize="0.8rem" sx={{ mr: 0.5 }} />
                                                    <Typography variant="body2">{formatNumber(video.video_like_count)}</Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <CommentIcon fontSize="0.8rem" sx={{ mr: 0.5 }} />
                                                    <Typography variant="body2">{formatNumber(video.video_comment_count)}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <ScheduleIcon fontSize="0.8rem" sx={{ mr: 0.5 }} />
                                                <Typography variant="body2">{formatDateToKST(video.video_publish_datetime)}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
}

export default YoutubeVideos;
