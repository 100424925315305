import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Link, CircularProgress, Box, Avatar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { formatNumber, formatDate } from "../utils/formatters";
import { useLinkProps } from "../utils/linkUtils";

function CommunityBest() {
    const theme = useTheme();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const linkProps = useLinkProps()();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get("/api/v1/community/best");
                setPosts(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/community/realtime-best"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">커뮤니티 베스트</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/community/realtime-best"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <List
                        sx={{
                            flexGrow: 1,
                            overflow: "auto",
                            maxHeight: "500px",
                            "&::-webkit-scrollbar": {
                                width: "6px",
                                backgroundColor: "#F5F5F5",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "10px",
                                backgroundClip: "padding-box",
                                border: "2px solid transparent",
                                "&:hover": {
                                    backgroundColor: "#555",
                                },
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                            },
                            scrollbarWidth: "thin",
                            scrollbarColor: "#888 #F5F5F5",
                        }}
                    >
                        {posts.map((post, index) => (
                            <React.Fragment key={post.post_id}>
                                {index > 0 && <Divider />}
                                <ListItem sx={{ py: 0.7 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            mr: 2,
                                            width: "52px",
                                            minWidth: "45px",
                                        }}
                                    >
                                        <Avatar
                                            src={`/images/community-icons/${post.community_id.toLowerCase()}.png`}
                                            alt={`${post.community_name} 아이콘`}
                                            variant="rounded"
                                            sx={{
                                                width: 52,
                                                height: 22,
                                                borderRadius: "5px",
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        >
                                            {post.community_name[0]}
                                        </Avatar>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                fontSize: "0.5rem",
                                                mt: 0.25,
                                                textAlign: "center",
                                                width: "100%",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {post.community_name}
                                        </Typography>
                                    </Box>
                                    <ListItemText
                                        primary={
                                            <Link
                                                href={post.post_url}
                                                {...linkProps}
                                                sx={{
                                                    fontSize: "1.0rem",
                                                    display: "block",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {post.post_title}
                                            </Link>
                                        }
                                        secondary={
                                            <Typography variant="body2" sx={{ fontSize: "0.65rem" }}>
                                                {`조회 ${formatNumber(post.read_count)} • 추천 ${formatNumber(post.upvote_count)} • 댓글 ${formatNumber(post.comment_count)} • ${formatDate(
                                                    post.post_datetime
                                                )}`}
                                            </Typography>
                                        }
                                        sx={{ my: 0 }}
                                    />
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
}

export default CommunityBest;
