import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, Grid, Box, CircularProgress, Rating, Link } from "@mui/material";
import axios from "axios";
import { Comment } from "@mui/icons-material";
import { formatNumber, formatKNumber } from "../utils/formatters";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLinkProps } from "../utils/linkUtils";

function ShoppingItems() {
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const linkProps = useLinkProps()();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get("/api/v1/shopping/best");
                setItems(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchItems();
    }, []);

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/shopping/realtime-shopping"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">인기 쇼핑 아이템</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/shopping/realtime-shopping"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : (
                    <Box sx={{ flexGrow: 1, overflow: "auto", maxHeight: "500px", mt: 1 }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                            {items.map((item) => (
                                <Grid item xs={6} sm={6} md={4} lg={4} key={`${item.shoppingmall_id}-${item.shopping_type}`}>
                                    <Card>
                                        <CardContent>
                                            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                                <img
                                                    src={`/images/shopping-icons/${item.shoppingmall_id.toLowerCase()}.png`}
                                                    alt={item.shoppingmall_name}
                                                    style={{
                                                        width: "40px",
                                                        height: "20px",
                                                        marginRight: "8px",
                                                        objectFit: "contain",
                                                    }}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.style.display = "none";
                                                    }}
                                                />
                                                <Typography variant="caption">{item.shoppingmall_name}</Typography>
                                            </Box>
                                            <Box sx={{ position: "relative", paddingTop: "100%", mb: 1 }}>
                                                <img
                                                    src={item.image_url}
                                                    alt={item.goods_title}
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </Box>
                                            <Link href={item.goods_url} {...linkProps} underline="hover">
                                                <Typography variant="subtitle2">{item.goods_title}</Typography>
                                            </Link>
                                            <Box sx={{ mt: 1 }}>
                                                <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                                                    {item.discount_rate > 0 && (
                                                        <>
                                                            <Typography variant="body2" component="span" color="text.secondary" sx={{ textDecoration: "line-through" }}>
                                                                {formatNumber(item.original_price)}원
                                                            </Typography>
                                                            <Typography variant="body2" color="error" sx={{ fontSize: "0.7rem", ml: 1 }}>
                                                                ({item.discount_rate}%)
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Box>
                                                <Typography variant="h6" component="span">
                                                    {item.discount_price > 0 ? formatNumber(item.discount_price) : formatNumber(item.original_price)}원
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" mt={1}>
                                                {item.rate_point > 0 ? (
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Rating value={item.rate_point} precision={0.1} readOnly size="small" />
                                                            <Typography variant="body2" sx={{ ml: 1 }}>
                                                                {item.rate_point.toFixed(1)}
                                                            </Typography>
                                                        </Box>
                                                        {item.comment_count > 0 && (
                                                            <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                                <Comment fontSize="xsmall" sx={{ mr: 0.5 }} />
                                                                <Typography variant="body2">{formatKNumber(item.comment_count)}</Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                ) : item.comment_count > 0 ? (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                        <Comment fontSize="xsmall" sx={{ mr: 0.5 }} />
                                                        <Typography variant="body2">{formatKNumber(item.comment_count)}</Typography>
                                                    </Box>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default ShoppingItems;
