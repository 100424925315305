import moment from "moment";
import "moment/locale/ko";

/**
 * 다양한 형식의 일시를 KST(한국 표준시)로 변환하고 포맷팅합니다.
 * @param {string} dateString - 다양한 형식의 날짜 문자열
 *                              (예: ISO 8601 "2024-08-19T09:00:03Z",
 *                                   RFC 2822 "Fri, 23 Aug 2024 11:00:00 -0700")
 * @returns {string} "YYYY년 M월 D일 요일 오전/오후 h:mm" 형식의 KST 날짜 문자열
 * @example
 * formatDateToKST("2024-08-19T09:00:03Z") // "2024년 8월 19일 월요일 오후 6:00"
 * formatDateToKST("Fri, 23 Aug 2024 11:00:00 -0700") // "2024년 8월 24일 토요일 오전 3:00"
 */
export const formatDateToKST = (dateString, format = "YYYY년 M월 D일 dddd A h:mm") => {
    moment.locale("ko"); // 한국어 설정
    return moment(dateString).format(format);
};

/**
 * 숫자에 천 단위로 쉼표를 추가합니다.
 * @param {number} num - 포맷할 숫자
 * @returns {string} 쉼표가 추가된 숫자 문자열
 * @example
 * formatNumber(1000000) // "1,000,000"
 */
export const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 숫자를 포맷팅하여 문자열로 반환합니다.
 * 1만 이상의 숫자는 'k'를 사용하여 간략히 표시합니다.
 *
 * @param {number} num - 포맷할 숫자
 * @returns {string} 포맷된 문자열
 *
 * @example
 * formatNumber(1234)     // "1,234"
 * formatNumber(12345)    // "12.3k"
 * formatNumber(123456)   // "123.5k"
 * formatNumber(1234567)  // "1,234.6k"
 * formatNumber(12345678) // "12,345.7k"
 */
export const formatKNumber = (num) => {
    if (num === null || num === undefined) return "-";

    if (num < 10000) {
        // 1만 미만의 숫자는 그대로 콤마만 추가하여 표시
        return num.toLocaleString("ko-KR");
    } else if (num < 1000000) {
        // 1만 이상 100만 미만의 숫자
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    } else {
        // 100만 이상의 숫자
        return (num / 1000).toLocaleString("ko-KR", { maximumFractionDigits: 1 }).replace(/\.0$/, "") + "k";
    }
};

/**
 * 숫자를 간략하게 포맷팅하여 문자열로 반환합니다.
 * 1000 이상의 숫자는 'k', 'M', 'B'를 사용하여 매우 간략히 표시합니다.
 *
 * @param {number} num - 포맷할 숫자
 * @returns {string} 포맷된 문자열
 *
 * @example
 * formatSimpleNumber(1234)        // "1.2k"
 * formatSimpleNumber(12345)       // "12k"
 * formatSimpleNumber(123456)      // "123k"
 * formatSimpleNumber(1234567)     // "1.2M"
 * formatSimpleNumber(12345678)    // "12M"
 * formatSimpleNumber(123456789)   // "123M"
 * formatSimpleNumber(1234567890)  // "1.2B"
 * formatSimpleNumber(12345678901) // "12B"
 */
export const formatSimpleNumber = (num) => {
    if (num === null || num === undefined) return "-";

    if (num < 1000) {
        return num.toString();
    } else if (num < 1000000) {
        return (num / 1000).toFixed(num < 10000 ? 1 : 0).replace(/\.0$/, "") + "k";
    } else if (num < 1000000000) {
        return (num / 1000000).toFixed(num < 10000000 ? 1 : 0).replace(/\.0$/, "") + "M";
    } else {
        return (num / 1000000000).toFixed(num < 10000000000 ? 1 : 0).replace(/\.0$/, "") + "B";
    }
};

/**
 * 다양한 형식의 날짜 문자열을 'YYYY-MM-DD HH:mm' 형식으로 포맷합니다.
 * @param {string} dateString - 포맷할 날짜 문자열
 * @returns {string} 포맷된 날짜 문자열 또는 유효하지 않은 경우 '-'
 */
export const formatDate = (dateString, transFormat = "YYYY-MM-DD HH:mm") => {
    if (!dateString) return "-";

    const formats = ["YY/MM/DD HH:mm", "YY-MM-DD HH:mm", "YYYY-MM-DD H:mm", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm", "YYYY-MM-DD HH"];

    const date = moment(dateString, formats, true);

    if (date.isValid()) {
        return date.format(transFormat);
    } else {
        console.warn(`Invalid date format: ${dateString}`);
        return "";
    }
};

export const formatPostDate = (dateString) => {
    if (!dateString) return "-";

    moment.locale("ko"); // 한국어 설정

    try {
        const date = moment(dateString);

        if (!date.isValid()) {
            throw new Error("Invalid date");
        }

        return date.fromNow(); // 상대적 시간 표시
    } catch (error) {
        console.error("Invalid date:", dateString, error);
        return "-";
    }
};

/**
 * 숫자를 원화 형식으로 포맷팅합니다.
 * @param {number} amount - 포맷할 금액
 * @returns {string} 원화 형식으로 포맷된 문자열
 * @example
 * formatCurrency(10000) // "₩10,000"
 */
export const formatCurrency = (amount) => {
    return new Intl.NumberFormat("ko-KR", { style: "currency", currency: "KRW" }).format(amount);
};

export const formatDuration = (duration) => {
    if (typeof duration !== "number" || isNaN(duration)) {
        return "N/A";
    }
    return `${duration.toFixed(2)}초`;
};

export const formatPercentage = (value) => {
    if (typeof value !== "number") return "0%";
    return `${value.toFixed(2)}%`;
};
