import React, { useState, useEffect, useMemo } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, useTheme, useMediaQuery, Box, Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse } from "@mui/material";
import { Menu as MenuIcon, ExpandLess, ExpandMore, Timeline as TimelineIcon, History as HistoryIcon, Star as StarIcon, EmojiEvents, CalendarMonth, DateRange } from "@mui/icons-material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import SettingsMenu from "./SettingsMenu";

function Header({ isVisible, style }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);

    const navItems = useMemo(
        () => [
            { name: "홈", path: "/" },
            {
                name: "커뮤니티",
                path: "/community",
                subItems: [
                    { name: "베스트 오브 베스트", path: "/community/best-of-best", icon: <StarIcon /> },
                    { name: "실시간 베스트", path: "/community/realtime-best", icon: <TimelineIcon /> },
                    { name: "지난 베스트", path: "/community/past-best", icon: <HistoryIcon /> },
                    { name: "실시간 커뮤니티 순위", path: "/community/realtime-ranking", icon: <EmojiEvents /> },
                    { name: "월간 커뮤니티 순위", path: "/community/monthly-ranking", icon: <CalendarMonth /> },
                    { name: "지난 커뮤니티 순위", path: "/community/yearly-ranking", icon: <DateRange /> },
                ],
            },
            {
                name: "검색어",
                path: "/search",
                subItems: [
                    { name: "실시간 검색어", path: "/search/realtime-search", icon: <TimelineIcon /> },
                    { name: "지난 검색어", path: "/search/past-search", icon: <HistoryIcon /> },
                ],
            },
            {
                name: "쇼핑",
                path: "/shopping",
                subItems: [
                    { name: "쇼핑 베스트", path: "/shopping/best-shopping", icon: <StarIcon /> },
                    { name: "실시간 쇼핑", path: "/shopping/realtime-shopping", icon: <TimelineIcon /> },
                    { name: "지난 쇼핑", path: "/shopping/past-shopping", icon: <HistoryIcon /> },
                ],
            },
            {
                name: "유튜브",
                path: "/youtube",
                subItems: [
                    { name: "실시간 유튜브", path: "/youtube/realtime-youtube", icon: <TimelineIcon /> },
                    { name: "지난 유튜브", path: "/youtube/past-youtube", icon: <HistoryIcon /> },
                ],
            },
        ],
        []
    );

    useEffect(() => {
        // 현재 경로에 따라 적절한 메뉴 열기
        const currentMainPath = "/" + location.pathname.split("/")[1];
        const menuToOpen = navItems.find((item) => item.path === currentMainPath);
        if (menuToOpen && menuToOpen.subItems) {
            setOpenMenu(menuToOpen.name);
        } else {
            setOpenMenu(null);
        }
    }, [location.pathname, navItems]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleMenuClick = (menuName) => (event) => {
        event.stopPropagation();
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    const handleListItemClick = (event, path) => {
        event.preventDefault();
        navigate(path);
        setDrawerOpen(false);
    };

    const isActive = (path) => {
        if (path === "/") {
            return location.pathname === "/";
        }
        return location.pathname.startsWith(path);
    };

    const drawer = (
        <Box sx={{ width: 250 }} role="presentation">
            <List>
                {navItems.map((item) => (
                    <React.Fragment key={item.name}>
                        {item.subItems ? (
                            <>
                                <ListItem button onClick={handleMenuClick(item.name)}>
                                    <ListItemText primary={item.name} />
                                    {openMenu === item.name ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={openMenu === item.name} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.subItems.map((subItem) => (
                                            <ListItem
                                                button
                                                key={subItem.name}
                                                selected={location.pathname === subItem.path}
                                                onClick={(event) => handleListItemClick(event, subItem.path)}
                                                sx={{ pl: 4 }}
                                            >
                                                <ListItemIcon>{subItem.icon}</ListItemIcon>
                                                <ListItemText primary={subItem.name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </>
                        ) : (
                            <ListItem button selected={isActive(item.path)} onClick={(event) => handleListItemClick(event, item.path)}>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="fixed" sx={style}>
            <Toolbar>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <img
                        src="/logo.png"
                        alt="오늘베스트 로고"
                        style={{
                            height: "40px",
                            marginRight: theme.spacing(2),
                        }}
                    />
                    <Typography variant="h6" component={RouterLink} to="/" sx={{ textDecoration: "none", color: "inherit" }}>
                        오늘베스트
                    </Typography>
                </Box>
                {isMobile ? (
                    <>
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Box sx={{ display: "flex" }}>
                        {navItems.map((item) => (
                            <Button
                                key={item.name}
                                component={RouterLink}
                                to={item.subItems ? item.subItems[0].path : item.path}
                                color="inherit"
                                sx={{
                                    mx: 1,
                                    borderRadius: "4px",
                                    "&:hover": {
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    },
                                    ...(isActive(item.path) && {
                                        borderBottom: "2px solid",
                                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    }),
                                }}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                )}
                <SettingsMenu />
            </Toolbar>
        </AppBar>
    );
}

export default Header;
