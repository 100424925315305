import React, { useState, useEffect } from "react";
import { Box, Container, useTheme, useMediaQuery, IconButton } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useSettings } from "../contexts/SettingsContext";

const HEADER_HEIGHT = 64;
const TRANSITION_DURATION = 300;
const SIDEBAR_WIDTH = 240;
const SIDEBAR_COLLAPSED_WIDTH = 60;

function Layout({ children }) {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isHomePage = location.pathname === "/";
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const { settings, updateSetting } = useSettings();

    const currentMainMenu = location.pathname.split("/")[1];
    const showSidebar = ["community", "search", "shopping", "youtube"].includes(currentMainMenu) && !isMobile;

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < HEADER_HEIGHT) {
                setIsHeaderVisible(true);
            } else {
                setIsHeaderVisible(currentScrollY <= lastScrollY);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollY]);

    const headerStyle = {
        transition: `transform ${TRANSITION_DURATION}ms ease-in-out`,
        transform: isHeaderVisible ? "translateY(0)" : `translateY(-${HEADER_HEIGHT}px)`,
    };

    const toggleSidebar = () => {
        updateSetting("sidebarExpanded", !settings.sidebarExpanded);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header isVisible={isHeaderVisible} style={headerStyle} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    pt: `${HEADER_HEIGHT}px`,
                }}
            >
                {showSidebar && (
                    <Box
                        sx={{
                            width: settings.sidebarExpanded ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
                            flexShrink: 0,
                            position: "fixed",
                            left: 0,
                            bottom: 0,
                            top: isHeaderVisible ? HEADER_HEIGHT : 0,
                            overflowY: "auto",
                            overflowX: "hidden",
                            borderRight: `1px solid ${theme.palette.divider}`,
                            bgcolor: theme.palette.background.paper,
                            zIndex: theme.zIndex.drawer,
                            transition: theme.transitions.create(["width", "top"], {
                                easing: theme.transitions.easing.sharp,
                                duration: TRANSITION_DURATION,
                            }),
                        }}
                    >
                        <Sidebar />
                        <IconButton
                            color="inherit"
                            aria-label="toggle sidebar"
                            onClick={toggleSidebar}
                            sx={{
                                position: "absolute",
                                bottom: 16,
                                left: settings.sidebarExpanded ? "calc(100% - 28px)" : 16,
                                zIndex: theme.zIndex.drawer + 1,
                                bgcolor: "background.paper",
                                "&:hover": {
                                    bgcolor: "action.hover",
                                },
                                transition: theme.transitions.create("left", {
                                    easing: theme.transitions.easing.sharp,
                                    duration: TRANSITION_DURATION,
                                }),
                            }}
                        >
                            {settings.sidebarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Box>
                )}
                <Container
                    maxWidth={isHomePage ? "lg" : false}
                    disableGutters={!isHomePage}
                    sx={{
                        flexGrow: 1,
                        width: isHomePage ? "auto" : "100%",
                        ml: showSidebar ? (settings.sidebarExpanded ? `${SIDEBAR_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`) : "auto",
                        mr: isHomePage ? "auto" : 0,
                        transition: theme.transitions.create("margin", {
                            easing: theme.transitions.easing.sharp,
                            duration: TRANSITION_DURATION,
                        }),
                    }}
                >
                    {children}
                </Container>
            </Box>
            <Footer />
        </Box>
    );
}

export default Layout;
