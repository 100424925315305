import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, CircularProgress, useTheme, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const COMMUNITY_COLORS = {
    "82C": "#4ba23e",
    BOB: "#166fbe",
    CLI: "#374373",
    DCI: "#030305",
    ETO: "#009907",
    FMK: "#456ac1",
    HUM: "#ee234b",
    HYG: "#fcc566",
    INS: "#36c063",
    INV: "#6fb92a",
    MLB: "#2f53a3",
    NAT: "#ee3920",
    PPO: "#ffa941",
    QOO: "#aabaf3",
    RUL: "#01256e",
    SLR: "#4a91db",
    TOD: "#cbed8d",
    YGO: "#850b0e",
    DDA: "#818181",
    ILB: "#ed1c24",
    GAS: "#0074c9",
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "5px",
                }}
            >
                <Typography variant="body2">{`${label}`}</Typography>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0px",
                        mt: 1,
                    }}
                >
                    {payload.map((entry, index) => (
                        <Typography key={`item-${index}`} variant="body2" style={{ color: entry.color }}>
                            {`${entry.name}: ${entry.value}`}
                        </Typography>
                    ))}
                </Box>
            </Box>
        );
    }
    return null;
};

function CommunityRankingChart() {
    const [rankingData, setRankingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await axios.get("/api/v1/community/yearly-ranking");
                setRankingData(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching yearly ranking:", err);
                setLoading(false);
            }
        };

        fetchRanking();
    }, []);

    const prepareChartData = () => {
        if (!rankingData.length) return [];

        const allDates = [...new Set(rankingData.flatMap((community) => community.monthly_data.map((data) => data.target_date)))].sort();

        return allDates.map((date) => {
            const dataPoint = { date };
            rankingData.forEach((community) => {
                const monthData = community.monthly_data.find((data) => data.target_date === date);
                if (monthData) {
                    dataPoint[community.community_name] = monthData.ranking;
                }
            });
            return dataPoint;
        });
    };

    const chartData = prepareChartData();
    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/community/yearly-ranking"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">지난 커뮤니티 순위</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/community/yearly-ranking"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box flexGrow={1} height={290}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={chartData} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis
                                    reversed={true}
                                    domain={[1, 20]}
                                    axisLine={false}
                                    tickLine={false}
                                    ticks={[1, 5, 10, 15, 20]}
                                    tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                                    width={21}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                {rankingData.map((community) => (
                                    <Line
                                        key={community.community_id}
                                        type="monotone"
                                        dataKey={community.community_name}
                                        stroke={COMMUNITY_COLORS[community.community_id] || "#000000"}
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default CommunityRankingChart;
