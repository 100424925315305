import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton, alpha } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";

function SearchInput({ onSearch, theme, placeholder, initialValue = "" }) {
    const [searchInput, setSearchInput] = useState(initialValue);

    useEffect(() => {
        setSearchInput(initialValue);
    }, [initialValue]);

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleSearch = () => {
        onSearch(searchInput);
    };

    const handleClear = () => {
        setSearchInput("");
        onSearch("");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={handleSearch}>
                            <Search />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: searchInput && (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClear}>
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            sx={{
                maxWidth: "600px",
                width: "100%",
                "& .MuiOutlinedInput-root": {
                    backgroundColor: alpha(theme.palette.background.paper, 0.8),
                    backdropFilter: "blur(10px)",
                    "&:hover": {
                        backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    },
                    "&.Mui-focused": {
                        backgroundColor: theme.palette.background.paper,
                    },
                },
            }}
        />
    );
}

export default React.memo(SearchInput);
