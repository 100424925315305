import React from "react";
import { Typography } from "@mui/material";

function HighlightedText({ text, highlight, variant = "body1" }) {
    if (!highlight.trim()) {
        return <Typography variant={variant}>{text}</Typography>;
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));

    return (
        <Typography variant={variant}>
            {parts.map((part, i) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <mark key={i} style={{ backgroundColor: "yellow", padding: 0 }}>
                        {part}
                    </mark>
                ) : (
                    part
                )
            )}
        </Typography>
    );
}

export default HighlightedText;
