import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Box,
    CircularProgress,
    Chip,
    Divider,
    useTheme,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Slide,
    Link,
    useScrollTrigger,
} from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import axios from "axios";
import { formatNumber, formatPostDate } from "../../utils/formatters";
import HighlightedText from "../../components/HighlightedText";
import StyledTableHeader from "../../components/StyledTableHeader";
import SearchInput from "../../components/SearchInput";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";

function RealtimeSearch() {
    const [searchTerms, setSearchTerms] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const observer = useRef();
    const lastScrollTop = useRef(0);
    const searchBarRef = useRef(null);
    const linkProps = useLinkProps()();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const lastSearchTermElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const fetchSearchTerms = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/v1/search/realtime-search?page=${page}&limit=20&search=${searchTerm}`);
            setSearchTerms((prevTerms) => (page === 1 ? response.data.data : [...prevTerms, ...response.data.data]));
            setHasMore(response.data.data.length > 0);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching trending search terms:", err);
            setError("데이터를 불러오는 데 실패했습니다.");
            setLoading(false);
        }
    }, [page, searchTerm]);

    useEffect(() => {
        setPage(1);
    }, [searchTerm]);

    useEffect(() => {
        fetchSearchTerms();
    }, [fetchSearchTerms, page, searchTerm]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDelta = scrollTop - lastScrollTop.current;
            const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;

            if (scrollTop > searchBarHeight) {
                if (scrollDelta > 10) {
                    setIsSearchVisible(false);
                } else if (scrollDelta < -10) {
                    setIsSearchVisible(true);
                }
            } else {
                setIsSearchVisible(true);
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSearch = useCallback(
        (newSearchTerm) => {
            setSearchTerm(newSearchTerm);
            setPage(1);
            scrollToTop();
        },
        [scrollToTop]
    );

    const tableHeaders = [{ label: "순위" }, { label: "이미지" }, { label: "검색어" }, { label: "검색량", align: "right" }, { label: "관련 뉴스", align: "center" }, { label: "등록", align: "right" }];

    const renderTableView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="실시간 인기 검색어">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {searchTerms.map((term, index) => (
                        <TableRow
                            key={`${term.trends_link}-${index}`}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            ref={index === searchTerms.length - 1 ? lastSearchTermElementRef : null}
                        >
                            <TableCell component="th" scope="row">
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <TrendingUp fontSize="xsmall" sx={{ mr: 0.5 }} />
                                    <Typography variant="body2">{term.ranks}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box
                                    component="img"
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        objectFit: "cover",
                                        borderRadius: 1,
                                    }}
                                    src={term.trends_thumbnails_uri || "/images/default-image.png"}
                                    alt={term.trends_title}
                                />
                            </TableCell>
                            <TableCell>
                                <Link
                                    href={term.trends_news_url}
                                    {...linkProps}
                                    sx={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                >
                                    <HighlightedText text={term.trends_title} highlight={searchTerm} variant="body2" />
                                </Link>
                            </TableCell>
                            <TableCell align="right">{formatNumber(term.trends_count)}</TableCell>
                            <TableCell align="left">
                                {term.trends_news_title && (
                                    <Box sx={{ mt: 1 }}>
                                        <Link
                                            href={term.trends_news_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                color: "inherit",
                                                textDecoration: "none",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            <Typography variant="body2">{term.trends_news_title}</Typography>
                                        </Link>
                                        <Typography variant="caption" color="textSecondary">
                                            {term.trends_news_source}
                                        </Typography>
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell align="right">{formatPostDate(term.trends_publish_datetime)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderCardView = () => (
        <Grid container spacing={2}>
            {searchTerms.map((term, index) => (
                <Grid item xs={12} sm={6} md={4} key={`${term.trends_link}-${index}`} ref={index === searchTerms.length - 1 ? lastSearchTermElementRef : null}>
                    <Card elevation={3} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <CardActionArea component="a" href={term.trends_news_url} {...linkProps}>
                            <CardMedia component="img" height="140" image={term.trends_thumbnails_uri || "/images/default-image.png"} alt={term.trends_title} />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Chip icon={<TrendingUp />} label={`${term.ranks}위`} color="primary" size="small" />
                                    <Typography variant="caption" color="text.secondary">
                                        {term.trends_publish_datetime_kor}
                                    </Typography>
                                </Box>
                                <Typography variant="h6" component="div" gutterBottom noWrap>
                                    <HighlightedText text={term.trends_title} highlight={searchTerm} />
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    검색량: {formatNumber(term.trends_count)}
                                </Typography>
                                {term.trends_news_title && (
                                    <Box sx={{ mt: 1 }}>
                                        <Link
                                            href={term.trends_news_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                color: "inherit",
                                                textDecoration: "none",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            <Typography variant="body2">{term.trends_news_title}</Typography>
                                        </Link>
                                        <Typography variant="caption" color="textSecondary">
                                            {term.trends_news_source}
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    실시간 검색어
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    지금 가장 많이 검색되는 키워드
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />

                <Slide appear={false} direction="down" in={isSearchVisible}>
                    <Box
                        ref={searchBarRef}
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: 64,
                            zIndex: 1,
                            backgroundColor: "transparent",
                            padding: "10px 0",
                        }}
                    >
                        <SearchInput onSearch={handleSearch} theme={theme} placeholder="검색어 검색..." initialValue={searchTerm} />
                    </Box>
                </Slide>

                {isMobile ? renderCardView() : renderTableView()}
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default RealtimeSearch;
