import React from "react";
import { Box, Container, Typography, Link, useTheme } from "@mui/material";

function Footer() {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                bgcolor: theme.palette.mode === "light" ? "background.paper" : "background.default",
                py: 2, // 패딩을 6에서 2로 줄임
                borderTop: `1px solid ${theme.palette.divider}`,
                mt: "auto", // 이 줄을 추가하여 footer를 항상 페이지 하단에 위치시킵니다.
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="body2" color="text.secondary" align="center">
                    {"Copyright © "}
                    <Link color="inherit" href="https://오늘베스트.com/">
                        오늘베스트
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {" • "}
                    모든 컨텐츠의 저작권은 해당 출처에 있습니다.
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
