import React from "react";
import { Container, Grid, Typography, Box, useTheme } from "@mui/material";
import CommunityBest from "../components/CommunityBest";
import RealtimeSearch from "../components/RealtimeSearch";
import ShoppingItems from "../components/ShoppingItems";
import YoutubeVideos from "../components/YoutubeVideos";
import CommunityRankingChart from "../components/CommunityRankingChart";
import CommunityRankingTrend from "../components/CommunityRankingTrend";
import ScrollToTopButton from "../components/ScrollToTopButton";

function Home() {
    const theme = useTheme();

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, paddingY: 4 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ marginBottom: 4, fontWeight: "bold", color: theme.palette.primary.main }}>
                    Today Hot Contents
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <CommunityRankingTrend />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CommunityRankingChart />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CommunityBest />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RealtimeSearch />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ShoppingItems />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <YoutubeVideos />
                    </Grid>
                </Grid>
                <ScrollToTopButton />
            </Container>
        </Box>
    );
}

export default Home;
