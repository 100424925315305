import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
if (MEASUREMENT_ID) {
    ReactGA.initialize(MEASUREMENT_ID);
} else {
    console.warn("Google Analytics Measurement ID not provided. GA will not be initialized.");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
