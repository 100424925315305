import React from "react";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Switch, useMediaQuery } from "@mui/material";
import { Settings, Brightness4, OpenInNew, MenuOpen } from "@mui/icons-material";
import { useSettings } from "../contexts/SettingsContext";

function SettingsMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { settings, updateSetting } = useSettings();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} color="inherit">
                <Settings />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <Brightness4 fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>화면 모드 (밝게/어둡게)</ListItemText>
                    <Switch edge="end" checked={settings.darkMode} onChange={(e) => updateSetting("darkMode", e.target.checked)} />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <OpenInNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>새 창에서 링크 열기</ListItemText>
                    <Switch edge="end" checked={settings.newWindow} onChange={(e) => updateSetting("newWindow", e.target.checked)} />
                </MenuItem>
                {!isMobile && (
                    <MenuItem>
                        <ListItemIcon>
                            <MenuOpen fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>사이드바 메뉴 펼치기</ListItemText>
                        <Switch edge="end" checked={settings.sidebarExpanded} onChange={(e) => updateSetting("sidebarExpanded", e.target.checked)} />
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

export default SettingsMenu;
