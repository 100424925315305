import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    Box,
    CircularProgress,
    Chip,
    Avatar,
    Divider,
    useTheme,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Slide,
    Link,
    useScrollTrigger,
} from "@mui/material";
import { Visibility, ThumbUp, Comment } from "@mui/icons-material";
import axios from "axios";
import { formatKNumber, formatPostDate } from "../../utils/formatters";
import HighlightedText from "../../components/HighlightedText";
import StyledTableHeader from "../../components/StyledTableHeader";
import SearchInput from "../../components/SearchInput";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";

function RealtimeBest() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const observer = useRef();
    const lastScrollTop = useRef(0);
    const searchBarRef = useRef(null);
    const linkProps = useLinkProps()();

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const fetchPosts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/v1/community/realtime-best?page=${page}&limit=100&search=${searchTerm}`);
            setPosts((prevPosts) => (page === 1 ? response.data.data : [...prevPosts, ...response.data.data]));
            setHasMore(response.data.data.length > 0);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching realtime best posts:", err);
            setError("데이터를 불러오는 데 실패했습니다.");
            setLoading(false);
        }
    }, [page, searchTerm]);

    useEffect(() => {
        setPage(1);
    }, [searchTerm]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, page, searchTerm]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDelta = scrollTop - lastScrollTop.current;
            const searchBarHeight = searchBarRef.current ? searchBarRef.current.offsetHeight : 0;

            if (scrollTop > searchBarHeight) {
                if (scrollDelta > 10) {
                    setIsSearchVisible(false);
                } else if (scrollDelta < -10) {
                    setIsSearchVisible(true);
                }
            } else {
                setIsSearchVisible(true);
            }

            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSearch = useCallback(
        (newSearchTerm) => {
            setSearchTerm(newSearchTerm);
            setPage(1);
            scrollToTop();
        },
        [scrollToTop]
    );

    const tableHeaders = [
        { label: "커뮤니티" },
        { label: "제목" },
        { label: "조회", align: "right" },
        { label: "추천", align: "right" },
        { label: "댓글", align: "right" },
        { label: "작성", align: "right" },
    ];

    const renderTableView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="실시간 베스트 게시물">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {posts.map((post, index) => (
                        <TableRow key={`${post.post_id}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} ref={index === posts.length - 1 ? lastPostElementRef : null}>
                            <TableCell component="th" scope="row">
                                <Chip
                                    avatar={
                                        <Avatar
                                            src={`/images/community-icons/${post.community_id.toLowerCase()}.png`}
                                            sx={{
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        />
                                    }
                                    label={post.community_name}
                                    size="small"
                                />
                            </TableCell>
                            <TableCell>
                                <Link
                                    href={post.post_url}
                                    {...linkProps}
                                    sx={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline",
                                        },
                                    }}
                                >
                                    <HighlightedText text={post.post_title} highlight={searchTerm} variant="body2" />
                                </Link>
                            </TableCell>
                            <TableCell align="right">{formatKNumber(post.read_count)}</TableCell>
                            <TableCell align="right">{formatKNumber(post.upvote_count)}</TableCell>
                            <TableCell align="right">{formatKNumber(post.comment_count)}</TableCell>
                            <TableCell align="right">{formatPostDate(post.post_datetime)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderCardView = () => (
        <Grid container spacing={2}>
            {posts.map((post, index) => (
                <Grid item xs={12} key={`${post.post_id}-${index}`} ref={index === posts.length - 1 ? lastPostElementRef : null}>
                    <Card elevation={3}>
                        <CardActionArea href={post.post_url} {...linkProps}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Chip
                                        avatar={
                                            <Avatar
                                                src={`/images/community-icons/${post.community_id.toLowerCase()}.png`}
                                                sx={{
                                                    "& img": {
                                                        objectPosition: "left",
                                                    },
                                                }}
                                            />
                                        }
                                        label={post.community_name}
                                        size="small"
                                    />
                                    <Typography variant="caption" color="text.secondary">
                                        {formatPostDate(post.post_datetime)}
                                    </Typography>
                                </Box>
                                <HighlightedText text={post.post_title} highlight={searchTerm} variant="subtitle1" />
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                    <Box display="flex" alignItems="center">
                                        <Visibility fontSize="small" color="action" />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1.0 }}>
                                            {formatKNumber(post.read_count)}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <ThumbUp fontSize="small" color="action" />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1.0 }}>
                                            {formatKNumber(post.upvote_count)}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Comment fontSize="small" color="action" />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1.0 }}>
                                            {formatKNumber(post.comment_count)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    실시간 베스트
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    지금 인기 있는 게시물
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />

                <Slide appear={false} direction="down" in={isSearchVisible}>
                    <Box
                        ref={searchBarRef}
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center",
                            position: "sticky",
                            top: 64,
                            zIndex: 1,
                            backgroundColor: "transparent",
                            padding: "10px 0",
                        }}
                    >
                        <SearchInput onSearch={handleSearch} theme={theme} placeholder="게시물 검색..." initialValue={searchTerm} />
                    </Box>
                </Slide>

                {isMobile ? renderCardView() : renderTableView()}
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default RealtimeBest;
