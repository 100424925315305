import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, CircularProgress, Grid, Chip, Avatar, useTheme, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import RankingTrendChart from "./RankingTrendChart";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function CommunityRankingTrend() {
    const [ranking, setRanking] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await axios.get("/api/v1/community/monthly-ranking");
                setRanking(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching monthly ranking:", err);
                setLoading(false);
            }
        };

        fetchRanking();
    }, []);

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, pb: 1 }}>
                    <Link
                        component={RouterLink}
                        to="/community/monthly-ranking"
                        color="inherit"
                        underline="none"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography variant="h6">커뮤니티별 순위</Typography>
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/community/monthly-ranking"
                        color="primary"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        <ArrowForwardIosIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ maxHeight: 290, overflowY: "auto" }}>
                        <Grid container spacing={1}>
                            {ranking.map((community, index) => (
                                <Grid item xs={6} key={community.community_id}>
                                    <Box display="flex" alignItems="center" mb={0.5}>
                                        <Box width="20px" textAlign="center" mr={0.5}>
                                            <Typography variant="body2" fontWeight="bold">
                                                {community.ranking}
                                            </Typography>
                                        </Box>
                                        <Box width="80px" mr={0.5}>
                                            <Chip
                                                avatar={<Avatar src={`/images/community-icons/${community.community_id.toLowerCase()}.png`} />}
                                                label={community.community_name}
                                                size="small"
                                                sx={{
                                                    maxWidth: "100%",
                                                    "& .MuiChip-label": { fontSize: "0.7rem", padding: "0 4px" },
                                                    "& img": {
                                                        objectPosition: "left",
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box flexGrow={1}>
                                            <RankingTrendChart trendData={community.ranking_trend} height={30} dot={false} animation={true} />
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default CommunityRankingTrend;
