import React, { useState, useEffect } from "react";
import { Container, Divider, Typography, Box, CircularProgress, useTheme, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import axios from "axios";
import { formatSimpleNumber } from "../../utils/formatters";
import ScrollToTopButton from "../../components/ScrollToTopButton";

const COMMUNITY_COLORS = {
    "82C": "#4ba23e",
    BOB: "#166fbe",
    CLI: "#374373",
    DCI: "#030305",
    ETO: "#009907",
    FMK: "#456ac1",
    HUM: "#ee234b",
    HYG: "#fcc566",
    INS: "#36c063",
    INV: "#6fb92a",
    MLB: "#2f53a3",
    NAT: "#ee3920",
    PPO: "#ffa941",
    QOO: "#aabaf3",
    RUL: "#01256e",
    SLR: "#4a91db",
    TOD: "#cbed8d",
    YGO: "#850b0e",
    DDA: "#818181",
    ILB: "#ed1c24",
    GAS: "#0074c9",
};

const CustomTooltip = ({ active, payload, label, selectedMetric }) => {
    if (active && payload && payload.length) {
        const sortedPayload = [...payload].sort((a, b) => {
            if (selectedMetric === "ranking") {
                return a.value - b.value;
            } else {
                return b.value - a.value;
            }
        });

        return (
            <Box
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "5px",
                }}
            >
                <Typography variant="body2">{`${label}`}</Typography>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "10px",
                        mt: 1,
                    }}
                >
                    {sortedPayload.map((entry, index) => (
                        <Typography key={`item-${index}`} variant="body2" style={{ color: entry.color }}>
                            {`${entry.name}: ${formatSimpleNumber(entry.value)}`}
                        </Typography>
                    ))}
                </Box>
            </Box>
        );
    }

    return null;
};

function YearlyRanking() {
    const [rankingData, setRankingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState("ranking");
    const [isLogScale, setIsLogScale] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const metricNames = {
        ranking: "순위",
        post_total: "게시물 수",
        read_total: "조회수",
        upvote_total: "추천수",
        comment_total: "댓글수",
        total_visits: "방문수",
        avg_visit_duration: "평균 방문 시간(분)",
        bounce_rate: "이탈률",
        ppv: "PPV",
    };

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await axios.get("/api/v1/community/yearly-ranking");
                setRankingData(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching yearly ranking:", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchRanking();
    }, []);

    const prepareChartData = (isDaily = false) => {
        if (!rankingData.length) return [];

        const allDates = [...new Set(rankingData.flatMap((community) => community.monthly_data.map((data) => data.target_date)))].sort();

        return allDates.map((date) => {
            const dataPoint = { date };
            rankingData.forEach((community) => {
                const monthData = community.monthly_data.find((data) => data.target_date === date);
                if (monthData) {
                    let value = monthData[selectedMetric];
                    if (selectedMetric === "avg_visit_duration") {
                        value = value / 60;
                    }
                    if (isDaily && !["ranking", "bounce_rate", "ppv"].includes(selectedMetric)) {
                        const daysInMonth = new Date(date.slice(0, 4), date.slice(5, 7), 0).getDate();
                        value = value / daysInMonth;
                    }
                    if (isLogScale && (value <= 0 || isNaN(value))) {
                        value = null;
                    }
                    dataPoint[community.community_name] = value;
                }
            });
            return dataPoint;
        });
    };

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
    };

    const handleLogScaleChange = (event) => {
        setIsLogScale(event.target.checked);
    };

    const getYAxisDomain = () => {
        if (selectedMetric === "ranking") {
            return [20, 1];
        }
        return ["auto", "auto"];
    };

    const getYAxisTicks = () => {
        if (selectedMetric === "ranking") {
            return [1, 5, 10, 15, 20];
        }
        return undefined;
    };

    const formatYAxis = (value) => {
        if (selectedMetric === "bounce_rate" || selectedMetric === "ppv") {
            return value.toFixed(1);
        }
        return formatSimpleNumber(value);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const chartData = prepareChartData();
    const dailyChartData = prepareChartData(true);

    const showSingleChart = ["ranking", "bounce_rate", "ppv", "avg_visit_duration"].includes(selectedMetric);

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" fontWeight="bold" color="primary" align="center" sx={{ width: "100%", mb: isMobile ? 2 : 0 }}>
                    지난 커뮤니티 순위
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                    {selectedMetric !== "ranking" && <FormControlLabel control={<Checkbox checked={isLogScale} onChange={handleLogScaleChange} name="logScale" />} label="로그 스케일" />}
                    <FormControl sx={{ minWidth: 200, ml: 2 }}>
                        <InputLabel id="metric-select-label">지표 선택</InputLabel>
                        <Select labelId="metric-select-label" id="metric-select" value={selectedMetric} label="지표 선택" onChange={handleMetricChange}>
                            {Object.entries(metricNames).map(([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Divider sx={{ mb: isMobile ? 2 : 4 }} />
            <Typography variant="h6" gutterBottom>
                월 {metricNames[selectedMetric]}
            </Typography>
            <ResponsiveContainer width="100%" height={showSingleChart ? 600 : 400}>
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                        domain={getYAxisDomain()}
                        reversed={selectedMetric === "ranking"}
                        ticks={getYAxisTicks()}
                        scale={isLogScale && selectedMetric !== "ranking" ? "log" : "auto"}
                        tickFormatter={formatYAxis}
                        width={33}
                        tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                    />
                    <Tooltip content={(props) => <CustomTooltip {...props} selectedMetric={selectedMetric} />} />
                    <Legend />
                    {rankingData.map((community) => (
                        <Line key={community.community_id} type="monotone" dataKey={community.community_name} stroke={COMMUNITY_COLORS[community.community_id] || "#000000"} strokeWidth={2} />
                    ))}
                </LineChart>
            </ResponsiveContainer>
            {!showSingleChart && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                        일 평균 {metricNames[selectedMetric]}
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={dailyChartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis
                                scale={isLogScale ? "log" : "auto"}
                                domain={isLogScale ? ["auto", "auto"] : getYAxisDomain()}
                                tickFormatter={formatYAxis}
                                width={33}
                                tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                            />
                            <Tooltip content={(props) => <CustomTooltip {...props} selectedMetric={selectedMetric} />} />
                            <Legend />
                            {rankingData.map((community) => (
                                <Line key={community.community_id} type="monotone" dataKey={community.community_name} stroke={COMMUNITY_COLORS[community.community_id] || "#000000"} strokeWidth={2} />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </>
            )}
            <ScrollToTopButton />
        </Container>
    );
}

export default YearlyRanking;
