import React from 'react';
import { TableHead, TableRow, TableCell } from "@mui/material";
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.1)
        : alpha(theme.palette.primary.main, 0.2),
    '& .MuiTableCell-head': {
        color: theme.palette.mode === 'light'
            ? theme.palette.primary.dark
            : theme.palette.primary.light,
        fontWeight: 'bold',
        fontSize: '0.9rem',
        padding: theme.spacing(1.5),
    },
}));

const StyledTableHeader = ({ headers }) => (
    <StyledTableHead>
        <TableRow>
            {headers.map((header, index) => (
                <TableCell key={index} align={header.align || 'left'}>
                    {header.label}
                </TableCell>
            ))}
        </TableRow>
    </StyledTableHead>
);

export default StyledTableHeader;