import React from "react";
import { List, ListItem, ListItemText, ListItemIcon, Box, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Timeline as TimelineIcon, History as HistoryIcon, Star as StarIcon, EmojiEvents, CalendarMonth, DateRange } from "@mui/icons-material";
import { useSettings } from "../contexts/SettingsContext";

const menuItems = {
    community: [
        { name: "베스트 오브 베스트", path: "/community/best-of-best", icon: <StarIcon /> },
        { name: "실시간 베스트", path: "/community/realtime-best", icon: <TimelineIcon /> },
        { name: "지난 베스트", path: "/community/past-best", icon: <HistoryIcon /> },
        { name: "실시간 커뮤니티 순위", path: "/community/realtime-ranking", icon: <EmojiEvents /> },
        { name: "월간 커뮤니티 순위", path: "/community/monthly-ranking", icon: <CalendarMonth /> },
        { name: "지난 커뮤니티 순위", path: "/community/yearly-ranking", icon: <DateRange /> },
    ],
    search: [
        { name: "실시간 검색어", path: "/search/realtime-search", icon: <TimelineIcon /> },
        { name: "지난 검색어", path: "/search/past-search", icon: <HistoryIcon /> },
    ],
    shopping: [
        { name: "쇼핑 베스트", path: "/shopping/best-shopping", icon: <StarIcon /> },
        { name: "실시간 쇼핑", path: "/shopping/realtime-shopping", icon: <TimelineIcon /> },
        { name: "지난 쇼핑", path: "/shopping/past-shopping", icon: <HistoryIcon /> },
    ],
    youtube: [
        { name: "실시간 유튜브", path: "/youtube/realtime-youtube", icon: <TimelineIcon /> },
        { name: "지난 유튜브", path: "/youtube/past-youtube", icon: <HistoryIcon /> },
    ],
};

function Sidebar() {
    const location = useLocation();
    const { settings } = useSettings();
    const currentMainMenu = location.pathname.split("/")[1];
    const currentMenuItems = menuItems[currentMainMenu] || [];

    return (
        <Box sx={{ width: "100%", overflowX: "hidden", overflowY: "auto" }}>
            <List>
                {currentMenuItems.map((item) => (
                    <Tooltip key={item.name} title={settings.sidebarExpanded ? "" : item.name} placement="right">
                        <ListItem button component={Link} to={item.path} selected={location.pathname === item.path}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            {settings.sidebarExpanded && <ListItemText primary={item.name} />}
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
        </Box>
    );
}

export default Sidebar;
