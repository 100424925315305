import React from "react";
import { LineChart, Line, ResponsiveContainer, YAxis, Tooltip, ReferenceLine, XAxis } from "recharts";
import { format, subMonths } from "date-fns";
import { ko } from "date-fns/locale";
import { Box, Typography } from "@mui/material";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <Box
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "4px",
                }}
            >
                <Typography variant="body2">{format(data.date, "yyyy-MM", { locale: ko })}</Typography>
                <Typography variant="body2" fontWeight="bold">
                    {`${payload[0].value}위`}
                </Typography>
            </Box>
        );
    }
    return null;
};

const RankingTrendChart = ({ trendData, height = 100, dot = true, animation = false }) => {
    if (!trendData) return null;
    const currentDate = new Date();
    const data = trendData
        .split(",")
        .map((rank, index) => ({
            month: 6 - index,
            rank: parseInt(rank),
            date: subMonths(currentDate, index + 1),
        }))
        .reverse();

    // 선형 회귀 계산
    const n = data.length;
    const sumX = data.reduce((acc, point) => acc + point.month, 0);
    const sumY = data.reduce((acc, point) => acc + point.rank, 0);
    const sumXY = data.reduce((acc, point) => acc + point.month * point.rank, 0);
    const sumXX = data.reduce((acc, point) => acc + point.month * point.month, 0);

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const trendLine = (x) => slope * x + intercept;

    // 추세선 색상 결정
    let trendColor;
    if (slope < -0.01) {
        trendColor = "green"; // 우상향 (순위가 올라감)
    } else if (slope > 0.01) {
        trendColor = "red"; // 하향 (순위가 내려감)
    } else {
        trendColor = "gray"; // 변화 없음
    }

    // 데이터의 최소, 최대 순위를 계산
    const minRank = Math.min(...data.map((d) => d.rank));
    const maxRank = Math.max(...data.map((d) => d.rank));

    return (
        <ResponsiveContainer width="100%" height={height}>
            <LineChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                <XAxis dataKey="month" hide={true} />
                <YAxis domain={[Math.max(1, minRank - 1), Math.min(20, maxRank + 1)]} reversed={true} hide={true} />
                <Tooltip content={<CustomTooltip />} />
                <Line type="monotone" dataKey="rank" stroke="#8884d8" strokeWidth={2} dot={dot} isAnimationActive={animation} />
                <ReferenceLine
                    stroke={trendColor}
                    strokeWidth={2}
                    strokeDasharray="3 3"
                    segment={[
                        { x: 1, y: trendLine(1) },
                        { x: 6, y: trendLine(6) },
                    ]}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default RankingTrendChart;
