import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Card, CardContent, CardActionArea, CardMedia, Box, CircularProgress, Chip, Divider, useTheme, useMediaQuery, Rating } from "@mui/material";
import axios from "axios";
import { formatNumber, formatCurrency } from "../../utils/formatters";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useLinkProps } from "../../utils/linkUtils";

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function BestShopping() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const linkProps = useLinkProps()();

    useEffect(() => {
        const fetchBestItems = async () => {
            try {
                const response = await axios.get("/api/v1/shopping/best");
                setItems(shuffleArray(response.data.data));
                setLoading(false);
            } catch (err) {
                console.error("Error fetching best items:", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchBestItems();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    쇼핑 베스트
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    각 쇼핑몰의 실시간 최고 인기 상품
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />
                <Grid container spacing={isMobile ? 2 : 3}>
                    {items.map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.goods_id}>
                            <Card
                                elevation={3}
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "translateY(-5px)",
                                        boxShadow: theme.shadows[10],
                                    },
                                }}
                            >
                                <CardActionArea href={item.goods_url} {...linkProps} sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <CardMedia component="img" height="200" image={item.image_url} alt={item.goods_title} sx={{ objectFit: "cover" }} />
                                    <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", p: isMobile ? 2 : 3 }}>
                                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                            <img
                                                src={`/images/shopping-icons/${item.shoppingmall_id.toLowerCase()}.png`}
                                                alt={item.shoppingmall_name}
                                                style={{
                                                    width: "40px",
                                                    height: "20px",
                                                    marginRight: "8px",
                                                    objectFit: "contain",
                                                }}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.style.display = "none";
                                                }}
                                            />
                                            <Typography variant="caption">{item.shoppingmall_name}</Typography>
                                        </Box>
                                        <Typography gutterBottom variant={isMobile ? "subtitle1" : "h6"} component="h2" sx={{ fontWeight: "medium", mb: 2 }}>
                                            {item.goods_title}
                                        </Typography>
                                        <Box sx={{ mt: "auto" }}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                <Typography variant="h6" color="primary">
                                                    {formatCurrency(item.discount_price || item.original_price)}
                                                </Typography>
                                                {item.discount_rate > 0 && <Chip label={`${item.discount_rate}% 할인`} color="secondary" size="small" />}
                                            </Box>
                                            {item.discount_rate > 0 && (
                                                <Typography variant="body2" color="text.secondary" sx={{ textDecoration: "line-through" }}>
                                                    {formatCurrency(item.original_price)}
                                                </Typography>
                                            )}
                                            {item.rate_point > 0 && (
                                                <Box display="flex" alignItems="center" mt={1}>
                                                    <Rating value={item.rate_point} readOnly size="small" />
                                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                                        ({formatNumber(item.comment_count)})
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <ScrollToTopButton />
        </Container>
    );
}

export default BestShopping;
