import React, { createContext, useState, useEffect, useContext } from "react";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        darkMode: false,
        newWindow: false,
        sidebarExpanded: true,
    });

    useEffect(() => {
        const savedSettings = localStorage.getItem("appSettings");
        if (savedSettings) {
            setSettings(JSON.parse(savedSettings));
        }
    }, []);

    const updateSetting = (key, value) => {
        const newSettings = { ...settings, [key]: value };
        setSettings(newSettings);
        localStorage.setItem("appSettings", JSON.stringify(newSettings));
    };

    return <SettingsContext.Provider value={{ settings, updateSetting }}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);
