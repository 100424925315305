import React, { useState, useEffect } from "react";
import {
    Container,
    Divider,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Chip,
    Avatar,
    Card,
    CardContent,
    Grid,
} from "@mui/material";
import { Visibility, ThumbUp, Comment, Description, Star, TrendingUp } from "@mui/icons-material";
import axios from "axios";
import { formatKNumber } from "../../utils/formatters";
import StyledTableHeader from "../../components/StyledTableHeader";
import ScrollToTopButton from "../../components/ScrollToTopButton";

function RealtimeRanking() {
    const [ranking, setRanking] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await axios.get("/api/v1/community/realtime-ranking");
                setRanking(response.data.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching realtime ranking:", err);
                setError("데이터를 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchRanking();
        const interval = setInterval(fetchRanking, 60000); // 1분마다 업데이트

        return () => clearInterval(interval);
    }, []);

    const tableHeaders = [
        { label: "순위" },
        { label: "커뮤니티" },
        { label: "게시물 수", align: "right" },
        { label: "조회수", align: "right" },
        { label: "추천수", align: "right" },
        { label: "댓글수", align: "right" },
        { label: "상위 10개 평균", align: "right" },
    ];

    const renderDesktopView = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="실시간 커뮤니티 랭킹 테이블">
                <StyledTableHeader headers={tableHeaders} />
                <TableBody>
                    {ranking.map((community) => (
                        <TableRow key={community.community_id}>
                            <TableCell component="th" scope="row">
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                    <TrendingUp fontSize="small" sx={{ mr: 0.5 }} />
                                    <Typography variant="body2">{community.ranking}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Chip
                                    avatar={
                                        <Avatar
                                            src={`/images/community-icons/${community.community_id.toLowerCase()}.png`}
                                            sx={{
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        />
                                    }
                                    label={community.community_name}
                                    size="small"
                                />
                            </TableCell>
                            <TableCell align="right">{formatKNumber(community.post_count)}</TableCell>
                            <TableCell align="right">{formatKNumber(community.total_reads)}</TableCell>
                            <TableCell align="right">{formatKNumber(community.total_upvotes)}</TableCell>
                            <TableCell align="right">{formatKNumber(community.total_comments)}</TableCell>
                            <TableCell align="right">{formatKNumber(community.avg_top_score)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderMobileView = () => (
        <Grid container spacing={2}>
            {ranking.map((community) => (
                <Grid item xs={12} key={community.community_id}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Chip
                                    avatar={
                                        <Avatar
                                            src={`/images/community-icons/${community.community_id.toLowerCase()}.png`}
                                            sx={{
                                                "& img": {
                                                    objectPosition: "left",
                                                },
                                            }}
                                        />
                                    }
                                    label={community.community_name}
                                    size="small"
                                />
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <TrendingUp fontSize="small" sx={{ mr: 0.5 }} />
                                    <Typography variant="h6" component="span">
                                        {community.ranking}위
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                <Box display="flex" alignItems="center">
                                    <Description fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.post_count)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Visibility fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.total_reads)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <ThumbUp fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.total_upvotes)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Comment fontSize="small" color="action" />
                                    <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                        {formatKNumber(community.total_comments)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" mt={1} justifyContent="flex-end">
                                <Star fontSize="small" color="action" />
                                <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                                    상위 10개 평균: {formatKNumber(community.avg_top_score)}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: isMobile ? 2 : 4 }}>
            <Box mb={isMobile ? 2 : 4}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" fontWeight="bold" color="primary">
                    실시간 커뮤니티 순위
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                    게시물 수(5%), 조회수(15%), 추천수(15%), 댓글수(15%), 상위 10개 게시물 평균 점수(50%)를 기준으로 선정
                </Typography>
                <Divider sx={{ mb: isMobile ? 2 : 4 }} />
            </Box>
            {isMobile ? renderMobileView() : renderDesktopView()}
            <ScrollToTopButton />
        </Container>
    );
}

export default RealtimeRanking;
